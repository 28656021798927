const SET_GUEST = 0;
const SET_USER = 1;
const SET_AD = 2;
const SET_MN = 3;
const LIST_FILTER = [20, 50, 100];

export {
  SET_GUEST,
  SET_USER,
  SET_AD,
  SET_MN,
  LIST_FILTER,
};
