import axios from 'axios';
import {
  failedGateway,
  loadingGateway,
  successGateway,
} from '../store/admin/gatewaySlice';
import store from '../store/index';


export const getGateway = () => (dispatch) => {
  const { auth } = store.getState();
  dispatch(loadingGateway);
  axios({
    method: 'GET',
    url: `${process.env.REACT_APP_BASE_URL}/api/${auth.alias}/gateway/find_all`,
    headers: {
      token: localStorage.getItem('AccessToken'),
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      dispatch(successGateway(res.data.data));
    })
    .catch((err) => {
      dispatch(failedGateway(err));
    });
};

export const deleteGateway = (id) => (dispatch) => {
  const { auth } = store.getState();
  dispatch(loadingGateway);
  axios({
    method: 'DELETE',
    url: `${process.env.REACT_APP_BASE_URL}/api/${auth.alias}/gateway/delete/${id}`,
    headers: {
      token: localStorage.getItem('AccessToken'),
      'Content-Type': 'application/json',
    },
  })
    .then((res) => {
      dispatch(successGateway(res.data.data));
    })
    .catch((err) => {
      dispatch(failedGateway(err));
    });
};
